import { getJson, prefixUrl } from '../helpers/network';
import { handleError } from '../helpers/error-log';

export const FETCH_CUSTOM_FIELDS_SUCCESS = 'FETCH_CUSTOM_FIELDS_SUCCESS';

function fetchCustomFieldsSuccess(resourceHash, serviceIds, fields) {
  return {
    type: FETCH_CUSTOM_FIELDS_SUCCESS,
    resourceHash,
    serviceIds,
    fields
  };
}

function getCustomFieldsParams({ booking }) {
  const slot = booking.get('slot');
  const services = booking.get('services');

  return {
    resourceHash: slot?.resourceHashId,
    serviceIds: services?.map(s => s.get('serviceId')).join(',')
  };
}

export function fetchCustomFields() {
  return (dispatch, getState) => {
    const { resourceHash, serviceIds } = getCustomFieldsParams(getState());

    if (!resourceHash || !serviceIds) {
      return Promise.resolve();
    }

    return getJson(prefixUrl(`/custom-fields/resource/${resourceHash}?srvIds=${serviceIds}`))
      .then(({ data }) => dispatch(fetchCustomFieldsSuccess(resourceHash, serviceIds, data)))
      .catch((error) => {
        handleError(error);
        return Promise.reject(error);
      });
  };
}
