import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, FormattedTime } from 'react-intl';
import Price from '../intl/price';

function getRemainingSlotMessageId(remainingSlots, notAvailable) {
  if (notAvailable) {
    return 'calendar.notAvailable';
  }
  if (remainingSlots <= 0) {
    return 'calendar.fullyBooked';
  }
  return remainingSlots === 1
    ? 'calendar.remainingSlot'
    : 'calendar.remainingSlots';
}

const CalendarSlot = ({
  onSelect, showResource, showPrice, time, resource, classBooking,
  maxSlots, bookedSlots, notAvailable, selected, price, priceFrom
}) => {
  const parts = time.split(':');
  const date = new Date().setHours(parts[0], parts[1]);
  const remainingSlots = maxSlots - bookedSlots + (selected ? 1 : 0);
  const disabled = notAvailable || remainingSlots <= 0;

  const className = classNames({
    'cb-slot': true,
    'cb-slot-selected': selected,
    'cb-slot-disabled': disabled
  });

  return (
    <a href="#" className={className} onClick={disabled ? null : onSelect}>
      <strong><FormattedTime value={date} /></strong>
      {showResource && resource && (
        <span className="cb-slot-resource">{resource.get('name')}</span>
      )}
      {showPrice && price > 0 && (
        <span className="cb-slot-price"><Price price={price} priceFrom={priceFrom} /></span>
      )}
      {classBooking && (
        <span className="cb-slot-remaining">
          <FormattedMessage
            id={getRemainingSlotMessageId(remainingSlots, notAvailable)}
            values={{ remainingSlots }}
          />
        </span>
      )}
    </a>
  );
};

export default CalendarSlot;
