import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { Route, Redirect } from 'react-router-dom';
import { fetchCustomFields } from '../../actions/custom-fields';
import {
  addCustomer, setBookingUseForeignPno, setBookingTermsAccepted,
  setBookingExtraTermsAccepted, setBookingAllowMarketing
} from '../../actions/booking';
import { getIsVerifyStep, getNextStep, Step } from '../../helpers/nav';
import { getMergedWebSettings, getPreferences } from '../../helpers/settings';
import { getMinimumAgeValidator } from '../../helpers/validation';
import { getCurrentMappedServices, getIsClassBooking } from '../../helpers/booking';
import { web } from '../../helpers/preference-keys';
import Nav from '../base/nav';
import CustomerForm from './customer-form';
import config from '../../config';

const formFocusDecorator = createDecorator();

class Customer extends React.Component {
  state = {
    initialValues: this.props.customer,
    error: null
  };

  componentDidMount() {
    this.props.fetchCustomFields();
  }

  addCustomer = (customer) => {
    const { webSettings } = this.props;
    const name = webSettings.separateNameFields
      ? `${customer.firstName} ${customer.lastName}`
      : customer.name;
    return this.props.addCustomer({ ...customer, name })
      .then(() => this.props.history.push(this.props.nextStep),
        error => this.setState({ error }));
  };

  getAllowMarketingChecked = () => {
    const { webSettings, allowMarketing } = this.props;
    return typeof allowMarketing === 'undefined'
      ? webSettings.allowMarketingDefaultChecked
      : allowMarketing;
  };

  render() {
    const { initialValues, error } = this.state;
    const {
      slot, features, webSettings, useForeignPno, termsAccepted, extraTermsAccepted, messageExtraInfos,
      customFields, minimumAgeValidator, expired, loading, isVerifyStep, isClassBooking
    } = this.props;

    if (!isVerifyStep && (expired || !slot)) {
      return <Redirect to={Step.Calendar} />;
    }

    return (
      <>
        <div className="cb-nav-container">
          <Route path="/:step?" component={Nav} />
        </div>

        <Form
          component={CustomerForm}
          initialValues={initialValues}
          onSubmit={this.addCustomer}
          decorators={[formFocusDecorator]}
          onUseForeignPno={this.props.setUseForeignPno}
          onTermsAccepted={this.props.setTermsAccepted}
          onExtraTermsAccepted={this.props.setExtraTermsAccepted}
          onAllowMarketing={this.props.setAllowMarketing}
          minimumAgeValidator={minimumAgeValidator}
          messageExtraInfos={messageExtraInfos}
          isClassBooking={isClassBooking}
          useForeignPno={useForeignPno}
          termsAccepted={termsAccepted}
          extraTermsAccepted={extraTermsAccepted}
          allowMarketing={this.getAllowMarketingChecked()}
          customFields={customFields}
          webSettings={webSettings}
          features={features}
          reserveError={error}
          loading={loading}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { app, booking, settings, customFields } = state;

  const slot = booking.get('slot');
  const mappedServices = getCurrentMappedServices(state);
  const webSettings = getMergedWebSettings(state);

  return {
    slot,
    customer: booking.get('customer') || config.customer,
    useForeignPno: booking.get('useForeignPno'),
    termsAccepted: booking.get('termsAccepted'),
    extraTermsAccepted: booking.get('extraTermsAccepted'),
    allowMarketing: booking.get('allowMarketing'),
    customFields: customFields.get('fields'),
    expired: booking.get('expired'),
    loading: app.get('loading'),
    features: settings.get('features'),
    webSettings,
    expandSummary: config.expandSummary || webSettings.alwaysShowSummary,
    messageExtraInfos: getPreferences(settings, mappedServices, web.messageExtraInfo),
    minimumAgeValidator: getMinimumAgeValidator(webSettings, slot),
    nextStep: getNextStep(state, props),
    isVerifyStep: getIsVerifyStep(state, props),
    isClassBooking: getIsClassBooking(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUseForeignPno: (useForeignPno) => {
      dispatch(setBookingUseForeignPno(useForeignPno));
    },
    setTermsAccepted: (termsAccepted) => {
      dispatch(setBookingTermsAccepted(termsAccepted));
    },
    setExtraTermsAccepted: (termsAccepted) => {
      dispatch(setBookingExtraTermsAccepted(termsAccepted));
    },
    setAllowMarketing: (allowMarketing) => {
      dispatch(setBookingAllowMarketing(allowMarketing));
    },
    fetchCustomFields: () => {
      return dispatch(fetchCustomFields());
    },
    addCustomer: (customer) => {
      return dispatch(addCustomer(customer));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
