/* eslint-disable camelcase */

export function isSet(value) {
  return value !== undefined && value !== null;
}

export function cardDescription(card) {
  const { brand, last4, exp_month, exp_year } = card;
  const brandName = brand && brand.charAt(0).toUpperCase() + brand.slice(1);
  return `${brandName} **** **** **** ${last4} (${exp_month}/${exp_year})`;
}

export function capitalizeFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatVehicleRegNo(value) {
  return value && value.toUpperCase().replace(/[^A-Z0-9]/, '');
}

export function isValidVehicleRegNo(value) {
  return value && /^[A-Z0-9]{2,7}$/.test(value);
}

export function getIdFromName(name) {
  return name?.toLowerCase().trim()
    .replace(/[^a-z0-9 ]/g, '').replace(/[ ]+/g, '-');
}
