import { Map } from 'immutable';

import { SET_LOADING, RESET_LOADING, SET_CONTAINER_WIDTH } from '../actions/app';
import { RESERVE_SLOT_SUCCESS, CONFIRM_BOOKING_SUCCESS, CONFIRMATION_OPTIONS_SUCCESS, ADD_CUSTOMER_SUCCESS } from '../actions/booking';
import { FETCH_REF_DATA_SUCCESS } from '../actions/ref-data';
import { FETCH_SLOTS_SUCCESS } from '../actions/slots';

export default function app(state = Map({
  loading: false,
  containerWidth: null
}), action = null) {
  switch (action.type) {
    case SET_LOADING:
      return state.set('loading', true);

    case RESET_LOADING:
    case FETCH_REF_DATA_SUCCESS:
    case FETCH_SLOTS_SUCCESS:
    case RESERVE_SLOT_SUCCESS:
    case ADD_CUSTOMER_SUCCESS:
    case CONFIRMATION_OPTIONS_SUCCESS:
    case CONFIRM_BOOKING_SUCCESS:
      return state.set('loading', false);

    case SET_CONTAINER_WIDTH:
      return state.set('containerWidth', action.containerWidth);

    default:
      return state;
  }
}
