import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { required, email, pno, orgNo, phone, matchPhone, matchEmail, composeValidators, requiredIfOrgName, vehicleRegNo } from '../../helpers/validation';
import { toggleCheckboxWithLink } from '../../helpers/forms';
import { formatVehicleRegNo } from '../../helpers/strings';
import { CaretRight } from '../base/images';
import Error from '../base/error';
import CheckBox from '../base/checkbox';
import Markdown from '../base/markdown';
import CustomerField from '../base/customer-field';
import CustomFields from './custom-fields';
import ProgressButton from '../base/button';
import PrivacyPolicy from '../base/privacy-policy';

class CustomerForm extends React.Component {
  toggleUseForeignPno = (ev) => {
    ev.preventDefault();
    const { useForeignPno } = this.props;
    this.props.onUseForeignPno(!useForeignPno);
  };

  toggleTermsAccepted = (ev) => {
    toggleCheckboxWithLink(ev,
      this.props.onTermsAccepted,
      this.props.termsAccepted);
  };

  toggleExtraTermsAccepted = (ev) => {
    toggleCheckboxWithLink(ev,
      this.props.onExtraTermsAccepted,
      this.props.extraTermsAccepted);
  };

  toggleAllowMarketing = (ev) => {
    toggleCheckboxWithLink(ev,
      this.props.onAllowMarketing,
      this.props.allowMarketing);
  };

  onSubmit = (ev) => {
    ev.preventDefault();
    return this.props.handleSubmit();
  };

  getHelpText = (id) => {
    const { intl } = this.props;
    return intl.messages[`customer.${id}`]
      ? intl.formatMessage({ id: `customer.${id}` })
      : null;
  };

  render() {
    const {
      termsAccepted, extraTermsAccepted, customFields, submitting, webSettings, minimumAgeValidator,
      useForeignPno, messageExtraInfos, features, reserveError, loading, allowMarketing, isClassBooking
    } = this.props;
    const {
      requireEmail, requirePNo, allowForeignPno, confirmPhone, confirmEmail, requireNotes, hideNotes,
      customMessageLabel, bookingExtraInfo, showTCBox, customTCBoxLabel, showExtraTCBox, extraTCBoxLabel,
      requireOrgNo, requireOrgName, showAllowMarketing, allowMarketingLabel, showExtraTCBoxInVerifyStep,
      separateNameFields
    } = webSettings;
    const orgOptionalHelpText = this.getHelpText('orgOptional');
    const { EnableVehicleBooking, EnableCompanyBooking } = features.toObject();
    const termsNotAccepted = showTCBox && !termsAccepted;
    const extraTermsNotAccepted = showExtraTCBox && !showExtraTCBoxInVerifyStep && !extraTermsAccepted;
    const submitDisabled = submitting || loading || termsNotAccepted || extraTermsNotAccepted;

    return (
      <form className="cb-form" onSubmit={this.onSubmit}>
        {EnableVehicleBooking && !isClassBooking && (
          <section>
            <CustomerField name="vehicleRegNo" parse={formatVehicleRegNo} validate={composeValidators(required, vehicleRegNo)} />
          </section>
        )}
        <section>
          {separateNameFields ? (
            <div className="cb-input-group-inline">
              <CustomerField name="firstName" validate={required} />
              <CustomerField name="lastName" validate={required} />
            </div>
          ) : (
            <CustomerField name="name" validate={required} />
          )}
          <CustomerField name="phone" validate={composeValidators(required, phone)} />
          {confirmPhone && (
            <CustomerField name="confirmPhone" autoComplete="new" validate={composeValidators(required, matchPhone)} />
          )}
          <CustomerField
            type="email"
            name="email"
            helpText={this.getHelpText('emailHelp')}
            optional={!requireEmail}
            validate={requireEmail ? composeValidators(required, email) : email}
          />
          {confirmEmail && (
            <CustomerField type="email" name="confirmEmail" autoComplete="new" validate={composeValidators(required, matchEmail)} />
          )}
          {requirePNo && (
            <>
              {/*
                This extra field is required to make the validation work
                properly when switching between regular and foreign pno
              */}
              {useForeignPno ? (
                <CustomerField name="disabledPno" helpText={this.getHelpText('pnoHelp')} disabled />
              ) : (
                <CustomerField
                  name="pno"
                  helpText={this.getHelpText('pnoHelp')}
                  validate={composeValidators(required, pno, minimumAgeValidator)}
                />
              )}
              {allowForeignPno && (
                <div className="cb-checkbox-container cb-foreign-pno" onClick={this.toggleUseForeignPno}>
                  <CheckBox checked={useForeignPno} />
                  <label><FormattedMessage id="customer.allowForeignPno" /></label>
                </div>
              )}
              {useForeignPno && (
                <CustomerField
                  hideLabel
                  name="foreignPno"
                  helpText={this.getHelpText('foreignPnoHelp')}
                  validate={required}
                />
              )}
            </>
          )}
        </section>
        {EnableCompanyBooking && !isClassBooking && (
          <section>
            <CustomerField
              name="orgName"
              optional={!requireOrgName}
              validate={requireOrgName ? required : null}
            />
            <CustomerField
              name="orgNo"
              optional={!requireOrgNo}
              validate={composeValidators(requireOrgNo ? required : requiredIfOrgName, orgNo)}
            />
            {!requireOrgName && !requireOrgNo && orgOptionalHelpText && (
              <span className="cb-input-help">{orgOptionalHelpText}</span>
            )}
          </section>
        )}
        <CustomFields customFields={customFields} />
        {!hideNotes && (
          <section>
            <CustomerField
              rows={3}
              name="note"
              label={customMessageLabel}
              extraInfos={messageExtraInfos}
              optional={!requireNotes}
              validate={requireNotes ? required : null}
            />
          </section>
        )}
        {(bookingExtraInfo || showTCBox || showExtraTCBox || showAllowMarketing) && (
          <section>
            {bookingExtraInfo && <Markdown className="cb-extra-info" source={bookingExtraInfo} />}
            {showTCBox && (
              <div className="cb-checkbox-container cb-accept" onClick={this.toggleTermsAccepted}>
                <CheckBox checked={termsAccepted} />
                <label>
                  {customTCBoxLabel
                    ? <Markdown source={customTCBoxLabel} />
                    : <FormattedMessage id="customer.acceptTerms" />}
                </label>
              </div>
            )}
            {showExtraTCBox && extraTCBoxLabel && !showExtraTCBoxInVerifyStep && (
              <div className="cb-checkbox-container cb-accept" onClick={this.toggleExtraTermsAccepted}>
                <CheckBox checked={extraTermsAccepted} />
                <label>
                  <Markdown source={extraTCBoxLabel} />
                </label>
              </div>
            )}
            {showAllowMarketing && allowMarketingLabel && (
              <div className="cb-checkbox-container cb-accept" onClick={this.toggleAllowMarketing}>
                <CheckBox checked={allowMarketing} />
                <label>
                  <Markdown source={allowMarketingLabel} />
                </label>
              </div>
            )}
          </section>
        )}
        {reserveError && <Error error={reserveError} />}
        <div className="cb-form-footer">
          <PrivacyPolicy />
          <ProgressButton
            type="submit"
            className="cb-button"
            progress={submitting || loading}
            disabled={submitDisabled}
          >
            <FormattedMessage id="continue" />
            <CaretRight className="right" />
          </ProgressButton>
        </div>
      </form>
    );
  }
}

export default injectIntl(CustomerForm);
