import moment from 'moment';
import queryString from 'query-string';
import { getJson, networkDelay, prefixUrl } from '../helpers/network';
import { setLoading, resetLoading } from './app';
import { getFirstSlot } from '../helpers/calendar';
import { handleError } from '../helpers/error-log';
import { reserveSlot } from './booking';
import config from '../config';

export const FETCH_SLOTS_SUCCESS = 'FETCH_SLOTS_SUCCESS';
export const RESET_SLOTS = 'RESET_SLOTS';

function getSlotsUrl(serviceIds, resourceIds, fromDate, toDate, isClassBooking) {
  const query = queryString.stringify({
    srvIds: serviceIds.join(','),
    resIds: resourceIds ? resourceIds.join(',') : undefined,
    fromDate: fromDate.format('YYYY-MM-DD'),
    toDate: toDate.format('YYYY-MM-DD')
  });

  if (config.resourceHash) {
    return prefixUrl(`/vip/resource/${config.resourceHash}/slots?${query}`);
  }
  return isClassBooking
    ? prefixUrl(`/classes/slots?${query}`)
    : prefixUrl(`/resources/slots?${query}`);
}

export function fetchSlots(serviceIds, resourceIds, fromDate, toDate, skipReducer, isClassBooking) {
  const url = getSlotsUrl(serviceIds, resourceIds, fromDate, toDate, isClassBooking);

  return (dispatch) => {
    dispatch(setLoading());
    return getJson(url)
      .then(({ data }) => {
        if (skipReducer) {
          return data;
        }
        return dispatch({
          type: FETCH_SLOTS_SUCCESS,
          resourceSlots: data.resourceSlots,
          nextAvailable: data.nextAvailable,
          fromDate,
          toDate
        });
      })
      .catch((error) => {
        handleError(error);
        dispatch(resetLoading());
        return Promise.reject(error);
      });
  };
}

export function fetchClassSlots(serviceIds, resourceIds, fromDate, toDate) {
  return fetchSlots(serviceIds, resourceIds, fromDate, toDate, false, true);
}

function fetchAndSelectFirstSlot(serviceIds, resourceIds, startDate) {
  const fromDate = moment(startDate);
  const toDate = moment(startDate).add(5, 'd');

  return (dispatch) => {
    return dispatch(fetchSlots(serviceIds, resourceIds, fromDate, toDate, true))
      .then(({ resourceSlots, nextAvailable }) => {
        const slot = getFirstSlot(resourceSlots);
        if (slot) {
          return dispatch(reserveSlot(slot));
        }
        if (nextAvailable && !startDate) {
          return dispatch(fetchAndSelectFirstSlot(serviceIds, resourceIds, nextAvailable));
        }
        throw Error('No available slots');
      });
  };
}

export function fetchAndSelectFirstSlotWithDelay(serviceIds, resourceIds) {
  return (dispatch) => {
    return Promise.all([
      dispatch(fetchAndSelectFirstSlot(serviceIds, resourceIds)),
      networkDelay()
    ]).finally(() => dispatch(resetLoading()));
  };
}

export function resetSlots() {
  return {
    type: RESET_SLOTS
  };
}
