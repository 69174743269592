import config from '../config';
import { debugLog } from './debug-log';

let pusher = null;
let channel = null;

export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';

function connect() {
  return new Promise((resolve) => {
    if (pusher) {
      resolve();
    } else {
      import('pusher-js').then(module => {
        const Pusher = module.default;
        if (config.debug || process.env.NODE_ENV !== 'production') {
          Pusher.logToConsole = true;
        }
        pusher = new Pusher(config.pusherKey, {
          cluster: config.pusherCluster
        });
        resolve();
      });
    }
  });
}

function disconnect() {
  if (pusher) {
    pusher.disconnect();
    pusher = null;
  }
}

function unsubscribeChannel() {
  if (channel) {
    channel.unbind_global();
    channel.unsubscribe();
    channel = null;
  }
}

function subscribeChannel(name, callback) {
  channel = pusher.subscribe(name);
  channel.bind_global(callback);
}

export function connectPusher(name, callback) {
  if (!config.enablePusher) {
    debugLog('Pusher: Skipping connect (enablePusher: false)');
    return;
  }
  connect().then(() => {
    unsubscribeChannel();
    subscribeChannel(name, callback);
  });
}

export function disconnectPusher() {
  unsubscribeChannel();
  disconnect();
}
