import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { fetchInitialData, releaseSlot } from '../actions/booking';
import { triggerNavigateEvent } from '../helpers/events';
import { Step, getFirstStep, scrollIntoView } from '../helpers/nav';
import { debugLog } from '../helpers/debug-log';
import { captureSentryException, errorLog } from '../helpers/error-log';
import { storeUtmTags } from '../helpers/tracking';
import { breakpointTablet, isBrowser } from '../env';
import config from '../config';

import Header from './base/header';
import Sidebar from './base/sidebar';
import Error from './base/error';
import LoadingOverlay from './base/loading-overlay';
import ViewportTracker from './base/viewport-tracker';
import Location from './location/location';
import Resource from './resource/resource';
import Service from './service/service';
import Calendar from './calendar/calendar';
import Customer from './customer/customer';
import Verify from './verify/verify';
import Confirmation from './confirmation';
import Footer from './base/footer';

const StepComponents = {
  [Step.Location]: Location,
  [Step.Resource]: Resource,
  [Step.Service]: Service,
  [Step.Calendar]: Calendar,
  [Step.Customer]: Customer,
  [Step.Verify]: Verify,
  [Step.Confirmation]: Confirmation
};

class Booking extends React.Component {
  state = {
    loaded: !this.props.settings.isEmpty(),
    error: null
  };

  componentDidMount() {
    storeUtmTags();
    debugLog('Initialized', config);
    window.addEventListener('cbk:cancel', this.onCancelEvent);

    try {
      if (!this.state.loaded) {
        this.props.fetchInitialData().then(
          () => this.setState({ loaded: true }),
          error => this.setState({ error })
        );
      }
    } catch (error) {
      errorLog('Initialize widget failed', error);
      this.setState({ error });
    }
  }

  componentDidUpdate(prevProps) {
    const { location, firstStep, slot } = this.props;

    if (prevProps.location !== location) {
      scrollIntoView();
      triggerNavigateEvent(prevProps.location, location, firstStep);
    }
    if (prevProps.slot && !slot) {
      this.props.releaseSlot();
    }
  }

  static getDerivedStateFromError() {
    return { error: true };
  }

  componentDidCatch(error) {
    captureSentryException(error);
  }

  componentWillUnmount() {
    window.removeEventListener('cbk:cancel', this.onCancelEvent);
  }

  onCancelEvent = () => {
    debugLog('Cancel event received, releasing slot');
    this.props.releaseSlot();
  };

  render() {
    const { firstStep, containerWidth } = this.props;
    const { loaded, error } = this.state;

    if (error) {
      return <Error error={error} />;
    }
    if (!loaded) {
      return <LoadingOverlay />;
    }

    const baseClassName = classNames({
      'cb-layout-base': true,
      'cb-breakpoint-tablet': containerWidth <= breakpointTablet,
      'cb-breakpoint-desktop': containerWidth > breakpointTablet
    });

    return (
      <>
        {isBrowser && <ViewportTracker />}
        <Route path="/:step?" component={Header} />

        <div className={baseClassName}>
          <Route path="/:step?" component={Sidebar} />

          <div className="cb-layout-main">
            <Switch>
              {Object.keys(StepComponents).map((step) => (
                <Route key={step} exact path={`/:step(${step})`} component={StepComponents[step]} />
              ))}
              <Route path="*" component={StepComponents[firstStep]} />
            </Switch>
            <Route exact path={`/:step(${Step.Verify})`} component={Customer} />
          </div>
        </div>

        {!config.isMicrosite && (
          <Route path="/:step?" component={Footer} />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, booking, settings } = state;
  return {
    settings,
    firstStep: getFirstStep(state),
    containerWidth: app.get('containerWidth'),
    slot: booking.get('slot')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInitialData: () => {
      return dispatch(fetchInitialData());
    },
    releaseSlot: () => {
      return dispatch(releaseSlot());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Booking);
