import React from 'react';
import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import { FormattedMessage } from 'react-intl';
import { getFullPno, isValidOrgNo, isValidPno } from './luhn-number';
import { isValidPhoneNumber, showPhoneNumberHint } from './phone-number';
import { isValidVehicleRegNo } from './strings';

const isEmpty = value => value === undefined || value === null || value === '';

export const required = value => isEmpty(value) && ({
  label: <FormattedMessage id="validation.required" />
});

export const phone = value => !isEmpty(value) && !isValidPhoneNumber(value) && ({
  label: <FormattedMessage id="validation.phone" />,
  hint: showPhoneNumberHint(value) && <FormattedMessage id="validation.phoneHint" />
});

export const email = value => !isEmpty(value) && !isEmail(value) && ({
  label: <FormattedMessage id="validation.email" />
});

export const pno = value => !isEmpty(value) && !isValidPno(value) && ({
  label: <FormattedMessage id="validation.pno" />
});

export const orgNo = value => !isEmpty(value) && !isValidOrgNo(value) && ({
  label: <FormattedMessage id="validation.orgNo" />
});

export const matchPhone = (value, { phone }) => !isEmpty(value) && value !== phone && ({
  label: <FormattedMessage id="validation.phoneMismatch" />
});

export const matchEmail = (value, { email }) => !isEmpty(value) && value !== email && ({
  label: <FormattedMessage id="validation.emailMismatch" />
});

export const requiredIfOrgName = (value, { orgName }) => isEmpty(value) && !isEmpty(orgName) && ({
  label: <FormattedMessage id="validation.orgNoRequired" />
});

export const vehicleRegNo = value => !isEmpty(value) && !isValidVehicleRegNo(value) && ({
  label: <FormattedMessage id="validation.vehicleRegNo" />
});

function getDateDescription(date) {
  const endOfYear = moment(date).endOf('year');
  const endOfMonth = moment(date).endOf('month');

  if (date.isSame(endOfYear, 'day')) {
    return date.format('YYYY');
  }
  if (date.isSame(endOfMonth, 'day')) {
    return date.format('MMMM YYYY');
  }
  return date.format('LL');
}

export const getMinimumAgeValidator = (webSettings, slot) => {
  const { minimumAgeLimit, birthDateNotAfter } = webSettings;

  if (!minimumAgeLimit && !birthDateNotAfter) {
    return null;
  }
  return (value) => {
    const fullPno = getFullPno(value || '');
    if (fullPno.length < 8) {
      return {
        label: <FormattedMessage id="validation.pno" />
      };
    }

    const birthDate = moment(fullPno.substring(0, 8), 'YYYYMMDD');
    if (!birthDate.isValid()) {
      return {
        label: <FormattedMessage id="validation.pno" />
      };
    }

    if (minimumAgeLimit) {
      const latestBirthDate = moment(slot.date).subtract(minimumAgeLimit, 'year');

      return birthDate.isAfter(latestBirthDate) && {
        label: <FormattedMessage id="validation.minimumAge" values={{ minimumAge: minimumAgeLimit }} />
      };
    }

    const latestBirthDate = moment(birthDateNotAfter);
    const birthDateDescription = getDateDescription(latestBirthDate);

    return birthDate.isAfter(latestBirthDate) && {
      label: <FormattedMessage id="validation.birthDate" values={{ birthDate: birthDateDescription }} />
    };
  };
};

export const composeValidators = (...validators) => (value, values) => {
  return validators.reduce((error, validator) => {
    return error || (validator && validator(value, values));
  }, undefined);
};
