import moment from 'moment';
import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';
import { getMappedResources, getResourceSelectionEnabled, getSelectedResourceIds } from './booking';
import { getShouldFilterSlots } from './settings';

function flattenSlots(resourceSlots) {
  return resourceSlots && resourceSlots.length > 0
    ? resourceSlots.map(s => s.slots).reduce((a, b) => a.concat(b))
    : [];
}

export function getFirstSlot(resourceSlots) {
  const slots = flattenSlots(resourceSlots);
  return slots.length > 0 ? slots[0] : null;
}

function getSlotTime(slot) {
  const parts = slot.get('time').split(':');
  return `${parts[0]}:${parts[1]}`;
}

function groupAndSortSlots(resourceSlots, fromDate, toDate, selectedSlot, weightedSort) {
  const slots = flattenSlots(resourceSlots);
  const groupedSlots = fromJS(slots).groupBy(s => s.get('date'));

  return Map().withMutations((days) => {
    const currentDate = fromDate.clone();
    while (currentDate < toDate) {
      const groupName = currentDate.format('YYYY-MM-DD');
      const slots = List().withMutations((list) => {
        if (groupedSlots.has(groupName)) {
          groupedSlots.get(groupName).forEach((slot) => {
            list.push(slot.merge({
              time: getSlotTime(slot),
              weight: Math.random()
            }));
          });
        }
        // Inject currently selected slot if it's not already in the list
        if (selectedSlot?.date === groupName && !list.find(s => s.get('key') === selectedSlot.key)) {
          list.push(fromJS({ ...selectedSlot, weight: 0 }));
        }
      });

      const weightedSlots = weightedSort ? slots.sortBy(s => s.get('weight')) : slots;
      days.set(currentDate.clone(), weightedSlots.sortBy(s => s.get('time')));
      currentDate.add(1, 'd');
    }
  });
}

function filterSlots(slots) {
  return slots.filter((slot, index, array) => {
    return array.map(slot => slot.get('time')).indexOf(slot.get('time')) === index;
  });
}

export const getGroupedAndFilteredSlots = createSelector(
  state => state.slots.get('resourceSlots'),
  state => state.slots.get('fromDate'),
  state => state.slots.get('toDate'),
  state => state.booking.get('slot'),
  getShouldFilterSlots,
  (resourceSlots, fromDate, toDate, selectedSlot, shouldFilter) => {
    if (!resourceSlots) {
      return null;
    }
    const slots = groupAndSortSlots(resourceSlots, fromDate, toDate, selectedSlot, shouldFilter);
    return shouldFilter ? slots.map(filterSlots) : slots;
  }
);

export const getCalendarDayCount = createSelector(
  state => state.app.get('containerWidth'),
  (containerWidth) => {
    return containerWidth < 500 ? 3 : 7;
  }
);

export function getCalendarViewDates(viewDate, dayCount) {
  const fromDate = moment(viewDate).startOf(dayCount === 7 ? 'week' : 'day');
  const toDate = moment(fromDate).add(dayCount - 1, 'd').endOf('day');

  return { fromDate, toDate };
}

export const getShowSlotResource = createSelector(
  getResourceSelectionEnabled,
  getSelectedResourceIds,
  getMappedResources,
  getShouldFilterSlots,
  (resourceSelectionEnabled, selectedResourceIds, mappedResources, shouldFilterSlots) => {
    const singleResource = mappedResources.count(r => r.get('available')) === 1;

    return resourceSelectionEnabled && !selectedResourceIds
      && !singleResource && !shouldFilterSlots;
  }
);

export const getShowSlotPrice = createSelector(
  state => state.slots.get('resourceSlots'),
  (resourceSlots) => {
    const slots = flattenSlots(resourceSlots);
    const prices = slots?.map(slot => slot.price);
    return prices?.some(price => price !== prices[0]);
  }
);
