import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { setBookingSlot } from '../../actions/booking';
import { CaretLeft } from '../base/images';
import { Step } from '../../helpers/nav';
import Modal from '../base/modal';

const Expired = ({ history }) => {
  const dispatch = useDispatch();

  const goBack = (ev) => {
    ev.preventDefault();
    dispatch(setBookingSlot(null));
    history.push(Step.Calendar);
  };

  return (
    <Modal
      size="small"
      title={(<FormattedMessage id="expired.heading" />)}
      onClose={goBack}
    >
      <p>
        <FormattedMessage id="expired.message" />
      </p>

      <button type="button" onClick={goBack} className="cb-button">
        <CaretLeft className="left" />
        <FormattedMessage id="expired.back" />
      </button>
    </Modal>
  );
};

export default Expired;
