import { Map } from 'immutable';

import { FETCH_SLOTS_SUCCESS, RESET_SLOTS } from '../actions/slots';
import { ADD_BOOKING_SERVICE, REMOVE_BOOKING_SERVICE, RESET_BOOKING_SERVICE } from '../actions/booking';

export default function slots(state = Map(), action = null) {
  switch (action.type) {
    case FETCH_SLOTS_SUCCESS: {
      const { resourceSlots, fromDate, toDate, nextAvailable } = action;
      return state.clear()
        .set('fromDate', fromDate)
        .set('toDate', toDate)
        .set('resourceSlots', resourceSlots)
        .set('nextAvailable', nextAvailable);
    }

    case RESET_SLOTS:
    case ADD_BOOKING_SERVICE:
    case REMOVE_BOOKING_SERVICE:
    case RESET_BOOKING_SERVICE:
      return state.clear();

    default:
      return state;
  }
}
