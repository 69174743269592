import queryString from 'query-string';
import { getJson, prefixUrl, prefixBaseUrl, prefixVipUrl } from '../helpers/network';
import { getShowResourceStep } from '../helpers/nav';
import { setLoading, resetLoading } from './app';
import { handleError } from '../helpers/error-log';
import config from '../config';

export const FETCH_REF_DATA_SUCCESS = 'FETCH_REF_DATA_SUCCESS';

function fetchRefDataSuccess({ services, resources, resourceMappings, addonMappings }) {
  return {
    type: FETCH_REF_DATA_SUCCESS,
    services,
    resources,
    resourceMappings,
    addonMappings
  };
}

export function fetchRefData() {
  const url = config.resourceHash ?
    prefixVipUrl(`/services/${config.resourceHash}/`) :
    prefixUrl('/ref-data/');

  return (dispatch) => {
    dispatch(setLoading());
    return getJson(url)
      .then(({ data }) => dispatch(fetchRefDataSuccess(data)))
      .catch((error) => {
        handleError(error);
        dispatch(resetLoading());
        return Promise.reject(error);
      });
  };
}

export function fetchMergedRefData() {
  return (dispatch, getState) => {
    const showResourceStep = getShowResourceStep(getState());

    const query = queryString.stringify({
      stableId: config.id.map(id => encodeURIComponent(id)).join(','),
      includeResourceMappings: showResourceStep ? true : undefined
    });
    const url = prefixBaseUrl(`/multilocation/ref-data/?${query}`);

    dispatch(setLoading());
    return getJson(url)
      .then(({ data }) => dispatch(fetchRefDataSuccess(data)))
      .catch((error) => {
        handleError(error);
        dispatch(resetLoading());
        return Promise.reject(error);
      });
  };
}
